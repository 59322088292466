import React, { ReactElement } from 'react';
import {
	CallToActionContainer,
	GetInTouchButton,
	Pointer1,
	Pointer2,
} from './ui/CallToActionUI';

const CallToAction = (): ReactElement => (
	<CallToActionContainer>
		<Pointer1 />
		<GetInTouchButton
			as="a"
			href="https://everlink.co/intouch"
		></GetInTouchButton>
		<Pointer2 />
	</CallToActionContainer>
);
export default CallToAction;
