import React, { ReactElement, useState } from 'react';
import MicControlButton from './MicControlButton';
import MicDisplay from './MicDisplay';
import { DetectionContainer } from './ui/MicDisplayUI';
import everlink from '../lib/loadEverlink';

const freqsToDetect: { [key: number]: string } = {
	20241: 'cube',
	20370: 'sphere',
	20499: 'pyramid',
};

const Detection = (): ReactElement => {
	const [micActive, setMicActive] = useState(false);
	const [shapeDetected, setShapeDetected] = useState('');

	everlink.onTokenFromAudio = (freq: number) => {
		const shape = freqsToDetect[freq];
		setShapeDetected(shape);
	};

	const stopDetectingFunction = () => {
		setShapeDetected('');
		setMicActive(false);
		everlink.stopListening();
	};

	const startDetectingFunction = async () => {
		setShapeDetected('');
		setMicActive(true);
		await everlink.startListening();
	};

	return (
		<>
			<DetectionContainer>
				<MicDisplay
					shapeDetected={shapeDetected}
					micActive={micActive}
				/>
			</DetectionContainer>

			<MicControlButton
				shapeDetected={shapeDetected}
				micActive={micActive}
				resetFunction={startDetectingFunction}
				stopDetectingFunction={stopDetectingFunction}
				startDetectingFunction={startDetectingFunction}
			/>
		</>
	);
};

export default Detection;
