import React, { ReactElement } from 'react';

import { CopyTitle, CopyBody, Line } from './ui/CopyUI';
import {
	InfoBackground,
	WhatToDo,
	Timeline,
	TimelineContainer,
	PhoneLaptop,
} from './ui/InfoUI';

const Info = (): ReactElement => (
	<InfoBackground>
		<CopyTitle>Test Everlink's Technology!</CopyTitle>
		<CopyBody>Try out our ultrasonic verification demo.</CopyBody>
		<CopyBody>
			We recommend using two smart-devices (e.g. phone & laptop), but feel
			free to experiment!
		</CopyBody>
		<PhoneLaptop />
		<Line></Line>
		<TimelineContainer>
			<WhatToDo />
			<Timeline />
		</TimelineContainer>
	</InfoBackground>
);

export default Info;
