import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { CopyBody, CopyTitle, CopySubTitle, Line } from './ui/CopyUI';
import EmittingShapes from './EmittingShapes';

const EmittingContainer = styled.div`
	width: 100%;
	margin-top: 30px;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

const Emitting = (): ReactElement => (
	<EmittingContainer>
		<CopyTitle style={{ marginBottom: '10px' }}>
			Technology Demonstration
		</CopyTitle>
		<CopySubTitle>Device 1: Choose a shape to emit</CopySubTitle>
		<CopyBody>(each shape has its own unique audiocode)</CopyBody>
		<EmittingShapes />
		<Line />
	</EmittingContainer>
);

export default Emitting;
