import styled from 'styled-components';
import everlinkLogo from '../../assets/images/everlinkLogo.png';

export const HeaderBar = styled.div`
	width: 100% - 30px;
	height: 80px;
	background-color: #090b25;
	color: white;
	padding: 15px;
	padding-bottom: 0;
	display: grid;
	grid-template-columns: 1fr repeat(1, auto) 1fr;
	justify-items: left;
	align-items: center;
	@media (max-width: 600px) {
		font-size: 30px;
	}
`;

export const HeaderLogo = styled.div`
	width: 184px;
	height: 60px;
	content: url(${everlinkLogo});
`;
