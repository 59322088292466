import styled from 'styled-components';
import stopEmittingButton from '../../assets/images/stopEmittingButton.png';

export const FullBox = styled.div`
	width: 153px;
	height: 250px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
`;

export const StopButton = styled.div`
	margin-top: 15px;
	width: 100%;
	height: 32px;
	background-color: transparent;
	background-image: url(${stopEmittingButton});
	background-size: cover;
	background-position: center;
	cursor: pointer;
	:hover {
		opacity: 0.9;
	}
`;

export const ShapeBox = styled.div`
	width: 153px;
	height: 200px;
	background-image: none;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	:hover {
		opacity: 0.9;
	}
`;

export const ShapeImage = styled.img`
	width: 100px;
	height: auto;
	margin-bottom: 40px;
`;
