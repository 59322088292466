import styled from 'styled-components';
import listeningBox from '../../assets/images/listeningBox.png';

export const MicDisplayIcon = styled.div`
	height: 121.5px;
	width: 81px;
`;

export const ShapeIcon = styled.div`
	height: 135px;
	width: 135px;
`;

export const ActiveMicIcon = styled.div`
	height: 212px;
	width: 171px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const DetectionContainer = styled.div`
	margin-top: 20px;
	color: white;
	width: 192.6px;
	height: 270px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 20px;
	font-size: 40px;
	background-image: url(${listeningBox});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;
