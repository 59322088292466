import React, { ReactElement } from 'react';
import { MicDisplayIcon, ActiveMicIcon, ShapeIcon } from './ui/MicDisplayUI';
import activeMic from '../assets/images/activeMic.png';
import inactiveMic from '../assets/images/inactiveMic.png';

import cube from '../assets/images/cube.png';
import sphere from '../assets/images/sphere.png';
import pyramid from '../assets/images/pyramid.png';

type MicDisplayProps = {
	shapeDetected: string;
	micActive: boolean;
};

const MicDisplayButton = ({
	shapeDetected,
	micActive,
}: MicDisplayProps): ReactElement => {
	if (shapeDetected === 'cube') {
		return (
			<>
				<ShapeIcon style={{ content: `url(${cube})` }} />
				<div>Detected</div>
			</>
		);
	}
	if (shapeDetected === 'sphere') {
		return (
			<>
				<ShapeIcon style={{ content: `url(${sphere})` }} />
				<div>Detected</div>
			</>
		);
	}
	if (shapeDetected === 'pyramid') {
		return (
			<>
				<ShapeIcon style={{ content: `url(${pyramid})` }} />
				<div>Detected</div>
			</>
		);
	}
	return (
		<ActiveMicIcon
			style={
				micActive
					? { backgroundImage: `url(${activeMic})` }
					: { backgroundImage: 'none' }
			}
		>
			<MicDisplayIcon style={{ content: `url(${inactiveMic})` }} />
		</ActiveMicIcon>
	);
};

export default MicDisplayButton;
