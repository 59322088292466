import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { CopyTitle, CopySubTitle, CopyBody, Line } from './ui/CopyUI';

import Detection from './Detection';
import CallToAction from './CallToAction';

const ListeningContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: auto;
	color: #090b25;
`;

const Listening = (): ReactElement => (
	<ListeningContainer>
		<CopySubTitle>Device 2: Detect your shape</CopySubTitle>
		<CopyBody>
			(Your device will only detect frequencies above 19.5kHz)
		</CopyBody>
		<Detection />
		<Line />
		<CopyTitle>Any feedback or have an idea?</CopyTitle>
		<CallToAction />
		<CopyBody style={{ width: '40%', fontSize: '10px' }}>
			Please bear in mind this is a demo version of our web-URL technology
			that is still being actively optimised, our core SDKs are designed
			for iOS and Android apps.
		</CopyBody>
	</ListeningContainer>
);

export default Listening;
