import styled from 'styled-components';

export const CopyTitle = styled.div`
	height: auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 50px;
	@media (max-width: 620px) {
		font-size: 40px;
	}
	@media (max-width: 400px) {
		font-size: 30px;
	}
`;

export const CopySubTitle = styled.div`
	height: auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 40px;
	@media (max-width: 400px) {
		font-size: 25px;
	}
`;

export const CopyBody = styled.div`
	width: 80%;
	margin: 2px auto;
	display: flex;
	justify-content: center;
	text-align: center;
	font-size: 25px;
	@media (max-width: 400px) {
		font-size: 20px;
	}
`;

export const Line = styled.div`
	margin: 30px auto;
	width: 60%;
	height: 0.2px;
	border-bottom: 1px solid;
`;
