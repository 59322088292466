import React, { ReactElement } from 'react';
import { ShapeBox, ShapeImage, FullBox, StopButton } from './ui/ShapeUI';

type ShapeProps = {
	shapeImage: string;
	shapeSelectedOverlay: string;
	selected: boolean;
	onClickShape: () => void;
	onClickStop: () => void;
};

const Shape = ({
	shapeImage,
	shapeSelectedOverlay,
	selected,
	onClickShape,
	onClickStop,
}: ShapeProps): ReactElement => (
	<FullBox>
		<ShapeBox
			style={
				selected
					? { backgroundImage: `url(${shapeSelectedOverlay})` }
					: { backgroundImage: 'none' }
			}
			onClick={() => onClickShape()}
		>
			<ShapeImage src={shapeImage} />
		</ShapeBox>
		{selected ? <StopButton onClick={onClickStop} /> : null}
	</FullBox>
);

export default Shape;
