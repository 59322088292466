import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import cube from '../assets/images/cube.png';
import sphere from '../assets/images/sphere.png';
import pyramid from '../assets/images/pyramid.png';
import cubeSelected from '../assets/images/cubeSelected.png';
import sphereSelected from '../assets/images/sphereSelected.png';
import pyramidSelected from '../assets/images/pyramidSelected.png';
import Shape from './Shape';

import everlink from '../lib/loadEverlink';

const ContainerDiv = styled.div`
	width: 80%;
	padding: 20px 10%;
	padding-bottom: 0px;
	padding-top: 40px;
	display: flex;
	flex-direction: row;
	height: auto;
	@media (max-width: 600px) {
		flex-direction: column;
		padding-top: 0px;
	}
	@media (min-width: 1000px) {
		width: 70%;
	}
`;

const freqsToPlay: { [key: string]: number } = {
	cube: 20241,
	sphere: 20370,
	pyramid: 20499,
};

const EmittingShapes = (): ReactElement => {
	const [selectedShape, changeSelectedShape] = useState('null');

	const reset = (): void => {
		changeSelectedShape('null');
		everlink.stopPlaying();
	};

	const playShape = async (shape: string): Promise<any> => {
		if (selectedShape !== shape) {
			changeSelectedShape(shape);
			everlink.stopPlaying();
			const token = freqsToPlay[shape];
			await everlink.playFreq(token);
		}
	};

	return (
		<ContainerDiv>
			<Shape
				shapeImage={cube}
				shapeSelectedOverlay={cubeSelected}
				selected={selectedShape === 'cube'}
				onClickShape={() => playShape('cube')}
				onClickStop={reset}
			></Shape>
			<Shape
				shapeImage={sphere}
				shapeSelectedOverlay={sphereSelected}
				selected={selectedShape === 'sphere'}
				onClickShape={() => playShape('sphere')}
				onClickStop={reset}
			></Shape>
			<Shape
				shapeImage={pyramid}
				shapeSelectedOverlay={pyramidSelected}
				selected={selectedShape === 'pyramid'}
				onClickShape={() => playShape('pyramid')}
				onClickStop={reset}
			></Shape>
		</ContainerDiv>
	);
};

export default EmittingShapes;
