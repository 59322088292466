import styled from 'styled-components';

import pulse from '../../assets/images/pulse.png';
import heresWhatYouDo from '../../assets/images/heresWhatYouDo.png';
import timeline from '../../assets/images/timeline.png';
import phoneLaptop from '../../assets/images/phoneLaptop.png';

export const InfoBackground = styled.div`
	padding: 20px 0;
	padding-top: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-image: url(${pulse});
	background-size: cover;
	background-position: center;
	height: auto;
	color: white;
`;

export const PhoneLaptop = styled.div`
	margin-top: 30px;
	width: 40%;
	background-image: url(${phoneLaptop});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	height: 80px;
	color: white;
	@media (max-width: 500px) {
		margin: 0;
		margin-top: 10px;
	}
`;

export const WhatToDo = styled.div`
	padding: 20px 0;
	width: 40%;
	background-image: url(${heresWhatYouDo});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	height: 6px;
	color: white;
	@media (max-width: 750px) {
		width: 80%;
		padding: 0;
		margin-bottom: 20px;
		height: 30px;
	}
`;

export const Timeline = styled.div`
	margin-top: 40px;
	width: 100%;
	height: 450px;
	margin-bottom: 20px;
	background-image: url(${timeline});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	color: white;
	@media (max-width: 750px) {
		width: 95%;
		margin-top: 0;
		margin-bottom: 10px;
		height: 320px;
	}
`;

export const TimelineContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
`;
