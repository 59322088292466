import styled from 'styled-components';
import getInTouch from '../../assets/images/getInTouch.png';
import pointer1 from '../../assets/images/pointer1.png';
import pointer2 from '../../assets/images/pointer2.png';

export const CallToActionContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin: 20px;
	width: 100%;
	height: auto;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

export const GetInTouchButton = styled.div`
	background-image: url(${getInTouch});
	width: 200px;
	height: 50px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	:hover {
		opacity: 0.9;
	}
`;

export const Pointer1 = styled.div`
	margin: 0 20px;
	width: 50px;
	height: 50px;
	background-image: url(${pointer1});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;

export const Pointer2 = styled.div`
	margin: 0 20px;
	width: 50px;
	height: 50px;
	background-image: url(${pointer2});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;
