import React, { ReactElement } from 'react';

import styled from 'styled-components';
import startDetecting from '../assets/images/startDetecting.png';
import stopDetecting from '../assets/images/stopDetecting.png';
import reset from '../assets/images/reset.png';

const ControlButton = styled.div`
	color: white;
	width: 192.6px;
	height: 45px;
	display: flex;
	font-size: 20px;
	font-size: 40px;
	background-image: url(${startDetecting});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
	:hover {
		opacity: 0.9;
	}
`;

type MicControlProps = {
	shapeDetected: string;
	micActive: boolean;
	resetFunction: () => void;
	stopDetectingFunction: () => void;
	startDetectingFunction: () => void;
};

const MicControlButton = ({
	shapeDetected,
	micActive,
	resetFunction,
	stopDetectingFunction,
	startDetectingFunction,
}: MicControlProps): ReactElement => {
	if (shapeDetected) {
		return (
			<ControlButton
				onClick={resetFunction}
				style={{ backgroundImage: `url(${reset})` }}
			></ControlButton>
		);
	}
	if (micActive) {
		return (
			<>
				<ControlButton
					onClick={stopDetectingFunction}
					style={{ backgroundImage: `url(${stopDetecting})` }}
				></ControlButton>
			</>
		);
	}
	return (
		<>
			<ControlButton
				onClick={startDetectingFunction}
				style={{ backgroundImage: `url(${startDetecting})` }}
			></ControlButton>
		</>
	);
};

export default MicControlButton;
