import React, { ReactElement } from 'react';
import Header from './components/Header';
import Info from './components/Info';
import Emitting from './components/Emitting';
import Listening from './components/Listening';

function App(): ReactElement {
	return (
		<div className="App">
			<Header />
			<Info />
			<Emitting />
			<Listening />
		</div>
	);
}

export default App;
