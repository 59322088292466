import React, { ReactElement } from 'react';
import { HeaderBar, HeaderLogo } from './ui/HeaderUI';

const Header = (): ReactElement => (
	<HeaderBar>
		<HeaderLogo as="a" href="https://everlink.co" />
	</HeaderBar>
);

export default Header;
