declare global {
	// slightly hacky way to access Everlink from window object
	interface Window {
		Everlink: any;
	}
}

const everlink = new window.Everlink('demoappkey1256');

export default everlink;
